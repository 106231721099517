.svg-cc {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 150px;
}

.svg-cc-sub {
  enable-background: new 0 0 90.5 91.7;
}

.square-cc {
  stroke-dasharray:50;
  stroke-dashoffset:-50;
  animation: draw-square-cc 2s infinite;
}

.square-cc-svg1 {
  fill: none;
  stroke: #ededed;
  stroke-width: 10;
  stroke-miterlimit: 10;
}

.square-cc-svg2 {
  fill: #e62566;
  stroke: #FF6EA6;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.square-cc-svg3 {
  fill: #fff;
}

@keyframes draw-square-cc {
  from {
      stroke-dashoffset: -100;
  }
  to {
      stroke-dashoffset: 100;
  }
}