@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #4a4a4a!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.img-logo-vidrieras {
  margin-left: 100px;
	width: 200px;
}

header.vidrieras {
	width: 100vw;
	background-color: #E72064;
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	height: 400px;
	display: flex;
	align-items: center;
}

header.vidrieras-sucursal {
	width: 100vw;
	background-color: #e62059;
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	height: 400px;
	display: flex;
  padding-left: 80px;
  padding-right: 80px;
	align-items: center;
}

header.vidrieras.moto {
  width: 100vw;
  background-image: url('./assets/imgs/img-header-moto-xl.png');
  background-color: #E72064;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
}

header.vidrieras.electro {
	width: 100vw;
	background-image: url('./assets/imgs/img-header-electro-xl.png');
	background-color: #ffffff;
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	height: 400px;
	display: flex;
	align-items: center;
}

div.vidrieras.description {
	background-color: #F2025D;
	padding: 20px;
}

p.vidrieras {
	font-size: 14px;
	color: #000000;
}
p.vidrieras.description {
	font-size: 14px;
	color: #ffffff;
	text-align: center;
}

.paddingVidrieras {
	padding-top: 30px;
	padding-right: 80px;
	padding-bottom: 30px;
	padding-left: 80px;
}

h2.vidrieras {
	font-family: 'Poppins';
	font-weight: 700;
	font-size: 30px;
	color: #E8225B;
	text-transform: none;
	margin-bottom: 20px;
}

h3.vidrieras {
	font-family: 'Poppins';
	font-weight: 700;
	font-size: 20px;
	color: #000000;
	text-transform: none;
	margin-bottom: 30px;
}

div.vidrieras.onboardingdni {
	background-color: #f5f5f5;
}

.h2-subtitle {
  font-family: 'Open Sans',sans-serif;
  font-size: 24px;
  color: #4a4a4a;
}

.selected-user {
  text-transform: capitalize;
  font-weight: 700!important;
  color: #4a4a4a;
  margin-top: 10px!important;
  font-size: 18px!important;
}

.subtitle {
  font-size: 30px!important;
  color: #4a4a4a;
  text-transform: none!important;
  font-family: inherit!important;
  font-weight: 500!important;
  line-height: 1.1!important;
}

.input-cellphone-evaluation-hint {
  font-size: 12px!important;
}

.identity-title {
  text-align: left!important;
  padding-left: 15px!important;
  margin-bottom: 10px!important;
  font-family: inherit!important;
  font-weight: 500!important;
  line-height: 1.1!important;
  font-size: 30px!important;
  color: #4a4a4a!important;
}

.terms-conditions {
  font-size: 12px !important;
}

.terms-conditions-link {
  color: #E91E63;
}

.terms-check {
  padding: 0px!important;
  padding-right: 1px!important;
}

.verification-code-cellphone {
  font-size: 14px!important;
}

.count-down {
  font-size: 12px!important;
}

.identity-ok {
  vertical-align: middle;
  color: #02c66a;
}

.capitalize {
  text-transform: capitalize;
}

.installments {
  font-weight: 500!important;
  line-height: 1.1!important;
  font-size: 18px!important;
  font-family: 'Open Sans',sans-serif!important;
  font-style: italic;
  margin-top: 15px!important;
}

.branch-icon {
  width: 15px;
  margin-right: 15px;
}

.branch-location {
  display: flex;
  align-items: center;
}

.whatsapp-image {
  height: 40px;
  position: fixed;
  bottom: 3%;
  width: 75%;
}

.rejected-description {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #F9CFD4;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
}

.banner-branch-title {
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  color: white !important;
  text-align: left;
}

.img-logo-vidrieras-sucursal {
  width: 450px;
}

.custom-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 200px;
}

.custom-card-title {
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.custom-card-description {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

@media only screen
and (max-device-width: 600px) {
  .logo-cc {
    width: 130px;
    margin-top: 5px;
  }

  .rejected-description {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #F9CFD4;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
  }
}

/* RESPONSIVE VIDRIERAS */
@media only screen and (min-width:800px) and (max-width:1000px) {
  header.vidrieras {
      width: 100vw;
      background-color: #E72064;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
      height: 300px;
      display: flex;
      align-items: center;
  }
  header.vidrieras-sucursal {
    width: 100vw;
    background-color: #e62059;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }
  header.vidrieras.moto {
    width: 100vw;
    background-image: url('./assets/imgs/img-header-moto-xs.png');
    background-color: #E72064;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 300px;
    display: flex;
    align-items: center;
  }
  header.vidrieras.electro {
    width: 100vw;
    background-image: url('./assets/imgs/img-header-electro-xs.png');
    background-color: #E72064;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 300px;
    display: flex;
    align-items: center;
  }
  .img-logo-vidrieras {
    margin-left: 100px;
    width: 200px;
  }

  .rejected-description {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #F9CFD4;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
  }

  .banner-branch-title {
    font-size: 48px !important;
  }
}
@media only screen and (min-width:500px) and (max-width:800px) {
  header.vidrieras {
      width: 100vw;
      background-color: #E72064;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
      height: 300px;
      display: flex;
      align-items: center;
  }
  header.vidrieras-sucursal {
    width: 100vw;
    background-color: #e62059;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
  }
  header.vidrieras.moto {
    width: 100vw;
    background-image: url('./assets/imgs/img-header-moto-xs.png');
    background-color: #E72064;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    position: relative;
    height: 250px;
    display: flex;
    align-items: center;
  }
  header.vidrieras.electro {
    width: 100vw;
    background-image: url('./assets/imgs/img-header-electro-xs.png');
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    position: relative;
    height: 250px;
    display: flex;
    align-items: center;
  }
  .img-logo-vidrieras {
    margin-left: 70px;
    width: 170px;
  }
  .paddingVidrieras {
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
  }

  .rejected-description {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #F9CFD4;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
  }

  .banner-branch-title {
    font-size: 36px !important;
  }
}
@media only screen and (max-width:500px) {
  header.vidrieras {
      width: 100vw;
      background-color: #E72064;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
      height: 300px;
      display: flex;
      align-items: center;
  }
  header.vidrieras-sucursal {
    width: 100vw;
    background-color: #e62059;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 200px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  header.vidrieras.moto {
    width: 100vw;
    background-image: url('./assets/imgs/img-header-moto-xs.png');
    background-color: #E72064;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    height: 150px;
    display: flex;
    align-items: center;
  }
  header.vidrieras.electro {
    width: 100vw;
    background-image: url('./assets/imgs/img-header-electro-xs.png');
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 150px;
    display: flex;
    align-items: center;
  }
  .img-logo-vidrieras {
    margin-left: 30px;
    width: 150px;
  }
  .paddingVidrieras {
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
  }

  .rejected-description {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #F9CFD4;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
  }

  .banner-branch-title {
    font-size: 30px !important;
    line-height: 36px !important;
  }

  .banner-branch-subtitle {
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: white !important;
    text-align: left !important;
  }

  .img-logo-vidrieras-sucursal {
    width: 150px;
  }

  .custom-card-container {
    flex-direction: column;
  }

  h2.vidrieras {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 26px;
    color: #E8225B;
    text-transform: none;
    margin-bottom: 20px;
  }
}